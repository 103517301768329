import React from "react"
import { Link } from "gatsby"
import Image from "gatsby-image"
import Icon from "../Icon/Icon.js"

const CardV3 = props => {
  return (
    <div className="card-v3">
      <Link
        to={props.url}
        className="card-v3__link"
        aria-label="Description of the link">
        <figure className="card-v3__img">
          <Image fluid={props.image} alt="" style={{ height: 100 + "%" }} />
        </figure>

        <div className="card-v3__content">
          <h3 className="text-sm color-white">{props.title}</h3>
        </div>

        <div className="card-v3__footer">
          <span>Ver más</span>
          <Icon
            iconName="icon-directional-angle-right"
            iconClassName="icon--sm"
          />
        </div>
      </Link>
    </div>
  )
}

export default CardV3
