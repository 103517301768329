import React from "react"
import Layout from "../components/Layout"

import { graphql, useStaticQuery } from "gatsby"
import CardV3 from "../components/CardV3/CardV3"
import bgImageDiagonalSection from "../images/bg-door.jpg"
import SectionHeadingH3 from "../components/SectionHeadingH3/SectionHeadingH3"
import DiagonalSection from "../components/DiagonalSection/DiagonalSection"

const Index = () => {
  const data = useStaticQuery(graphql`
    query {
      mainImageMaestroBosco: file(
        relativeDirectory: {
          eq: "promociones/Edificio Maestro Bosco/Descripcion"
        }
        publicURL: { regex: "/(portada)/" }
      ) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      mainImageJardinHidalgo: file(
        relativeDirectory: { eq: "promociones/Jardin del Hidalgo/Descripcion" }
        publicURL: { regex: "/(portada)/" }
      ) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      mainImagePauII: file(
        relativeDirectory: { eq: "promociones/M6 Pau II/Descripcion" }
        publicURL: { regex: "/(portada)/" }
      ) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      mainImageSanUrbano: file(
        relativeDirectory: {
          eq: "promociones/Residencial San Urbano/Descripcion"
        }
        publicURL: { regex: "/(portada)/" }
      ) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <DiagonalSection bgImage={bgImageDiagonalSection}>
        <div className="text-component">
          <h1 className="text-lg font-primary-black">Viviendas de calidad</h1>
          <p className="text-md">
            Promoción y construcción de viviendas VPO, Renta libre y Locales
            comerciales
          </p>
        </div>
      </DiagonalSection>
      <div className="container margin-bottom-xl" id="promociones">
        <SectionHeadingH3 sectionTitle="Promociones" />
        <div className="grid gap-md">
          <div className="col-12 col-6@sm col-3@md">
            <CardV3
              title="Edificio Maestro Bosco"
              image={data.mainImageMaestroBosco.childImageSharp.fluid}
              url="/maestro-bosco"
            />
          </div>
          <div className="col-12 col-6@sm col-3@md">
            <CardV3
              title="Jardín del Hidalgo"
              image={data.mainImageJardinHidalgo.childImageSharp.fluid}
              url="/jardin-hidalgo"
            />
          </div>
          <div className="col-12 col-6@sm col-3@md">
            <CardV3
              title="M6 Pau II"
              image={data.mainImagePauII.childImageSharp.fluid}
              url="/pau-2"
            />
          </div>
          <div className="col-12 col-6@sm col-3@md">
            <CardV3
              title="Residencial San Urbano"
              image={data.mainImageSanUrbano.childImageSharp.fluid}
              url="/san-urbano"
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Index
